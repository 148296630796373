import { AssetSummaryExternalIcon } from './AssetSummaryExternalIcon';
import { SlaType } from './SlaType';
import { TaskPriorityReadExternal } from './TaskPriorityReadExternal';
import { TaskResolutionReasonReadExternal } from './TaskResolutionReasonReadExternal';
import { TaskStatusReadExternal } from './TaskStatusReadExternal';
import { TaskTypeReadExternalActivities } from './TaskTypeReadExternalActivities';
import { TaskTypeReadExternalExtMetadataConfig } from './TaskTypeReadExternalExtMetadataConfig';
import { TaskTypeSummaryExternalTaskTypeGroup } from './TaskTypeSummaryExternalTaskTypeGroup';
import { UID } from './UID';
import { UIDReadOnly } from './UIDReadOnly';
import { URNReadOnly } from './URNReadOnly';

export interface TaskTypeReadExternal {
  uid: UIDReadOnly;
  urn: URNReadOnly;
  slug?: string;
  taskTypeGroupUid?: UID;
  primaryAssetRequired?: boolean;
  descriptionDisplay?: TaskTypeReadExternal_DescriptionDisplayEnum;
  orderingAllowed?: boolean;
  slaType?: SlaType;
  useManagerAssistant?: boolean;
  statuses?: Array<TaskStatusReadExternal>;
  priorities?: Array<TaskPriorityReadExternal>;
  activities?: Array<TaskTypeReadExternalActivities>;
  resolutionReasons?: Array<TaskResolutionReasonReadExternal>;
  extMetadataConfig?: Array<TaskTypeReadExternalExtMetadataConfig>;
  taskTypeGroup?: TaskTypeSummaryExternalTaskTypeGroup;
  icon?: AssetSummaryExternalIcon;
  displayName: string;
  initialActivityDisplayName?: string;
}

export type TaskTypeReadExternal_DescriptionDisplayEnum = 'NEVER' | 'READONLY' | 'WRITE';

export const TaskTypeReadExternal_DescriptionDisplayEnum = {
  NEVER: 'NEVER' as TaskTypeReadExternal_DescriptionDisplayEnum,
  READONLY: 'READONLY' as TaskTypeReadExternal_DescriptionDisplayEnum,
  WRITE: 'WRITE' as TaskTypeReadExternal_DescriptionDisplayEnum,
};
